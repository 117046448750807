module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins:400,600,700"]}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OnePortfolio","short_name":"OnePortfolio","start_url":"/","background_color":"#012233","theme_color":"#002031","display":"standalone","icon":"static/oneportfolio-icon.png","include_favicon":true,"icons":[{"src":"https://oneportfolio.io/static/favicon-32x32.png","sizes":"32x32","type":"image/png","purpose":"any maskable"},{"src":"https://oneportfolio.io/static/android-chrome-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"https://oneportfolio.io/static/android-chrome-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"},{"src":"https://oneportfolio.io/static/apple-touch-icon.png","sizes":"180x180","type":"image/png"}],"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
