exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-dividend-calculator-jsx": () => import("./../../../src/pages/dividend-calculator.jsx" /* webpackChunkName: "component---src-pages-dividend-calculator-jsx" */),
  "component---src-pages-dividend-tracker-jsx": () => import("./../../../src/pages/dividend-tracker.jsx" /* webpackChunkName: "component---src-pages-dividend-tracker-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-guides-csv-file-how-to-import-jsx": () => import("./../../../src/pages/guides/csv-file/how-to-import.jsx" /* webpackChunkName: "component---src-pages-guides-csv-file-how-to-import-jsx" */),
  "component---src-pages-guides-interactive-brokers-how-to-export-jsx": () => import("./../../../src/pages/guides/interactive-brokers/how-to-export.jsx" /* webpackChunkName: "component---src-pages-guides-interactive-brokers-how-to-export-jsx" */),
  "component---src-pages-help-center-jsx": () => import("./../../../src/pages/help-center.jsx" /* webpackChunkName: "component---src-pages-help-center-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-tracker-jsx": () => import("./../../../src/pages/portfolio-tracker.jsx" /* webpackChunkName: "component---src-pages-portfolio-tracker-jsx" */),
  "component---src-pages-portfolio-visualizer-jsx": () => import("./../../../src/pages/portfolio-visualizer.jsx" /* webpackChunkName: "component---src-pages-portfolio-visualizer-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

